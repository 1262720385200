import React, { useEffect, useState } from 'react';
import { BaseEventHandler } from '@bm-js/h2o-shared';

type Props<T> = {
  onChange: BaseEventHandler;
  value?: string;
  items: T[];
  identifier: string;
  itemKey: keyof T;
  itemNameKey: keyof T;
  disabled?: boolean;
  placeholder?: string;
  emitAllChanges?: boolean;
};

const DataList = <T,>({
  onChange,
  value,
  items,
  identifier,
  itemKey,
  itemNameKey,
  disabled,
  placeholder,
  emitAllChanges,
}: Props<T>) => {
  const [internalValue, setInternalValue] = useState(
    (items.find((item) => item[itemKey] === value)?.[itemNameKey] ||
      '') as string
  );

  const handleChange: BaseEventHandler = (e) => {
    setInternalValue(e.target.value);
    const selection = items.find(
      (item) => item[itemNameKey] === e.target.value
    );
    if (emitAllChanges) {
      onChange(e);
      return;
    }
    if (selection) {
      onChange({ target: { value: selection[itemKey] as string } });
    }
  };

  useEffect(() => {
    if (emitAllChanges && value !== undefined) {
      setInternalValue(value);
      return;
    }
    setInternalValue(
      (items.find((item) => item[itemKey] === value)?.[itemNameKey] ||
        '') as string
    );
    //eslint-disable-next-line
  }, [value, items, emitAllChanges]);
  return (
    <div>
      <input
        placeholder={placeholder}
        list={identifier}
        value={internalValue}
        onChange={handleChange}
        disabled={disabled}
      />
      <datalist id={identifier}>
        {items.map((item) => (
          <option
            value={item[itemNameKey] as string}
            key={item[itemKey] as string}
          />
        ))}
      </datalist>
    </div>
  );
};

export default DataList;
